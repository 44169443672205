const useLoginRouter = () => {
  const navigateToLogin = (redirectTo: string) => {
    let suffixRedirectURL = '/auth/login'
    if (redirectTo == 'signup') {
      suffixRedirectURL += '?screen_hint=signup'
    } else if (redirectTo == 'login') {
      // adding the current path for redirection after login path
      const currentPath = window.location.pathname
      suffixRedirectURL += `?redirect_to=${encodeURIComponent(currentPath)}`
    }
    if (process.env.NODE_ENV === 'development') {
      window.location.href = `${process.env.API_LOCALHOST_ORIGIN_URL}${suffixRedirectURL}`
    } else {
      window.location.href = window.location.origin + suffixRedirectURL
    }
  }
  return navigateToLogin
}

export default useLoginRouter
